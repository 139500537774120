
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getConfigEnv, getComponent, sleep } from '@/utils/helpers';
import tasqsListModule from '@/store/modules/tasqsListModule';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import Multiselect from 'vue-multiselect';
import { SHOW_ALERT } from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import scheduleModule from '@/store/modules/scheduleModule';
import { datadogRum } from '@datadog/browser-rum';
import metaDataModule from '@/store/modules/metaDataModule';
import isOnline from 'is-online';
import gptModule from '@/store/modules/gptModule';
import GptMixin from '@/components/gpt/GptMixin';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    TasqMobileFormSubmission: () => getComponent('tasqs/TasqMobileFormSubmission'),
    TasqWellStatusBanner: () => getComponent('tasqs/TasqWellStatusBanner'),
    ChartTimeRange: () => getComponent('tasqs/charts/ChartTimeRange'),
    manualTasq: () => getComponent('tasqs/TasqManualPopup'),
    Multiselect,
    WellSignalsView: () => getComponent('tasqs/WellSignalsView'),
    DateRangePicker,
  },
})
export default class TasqsMobile extends mixins(DataLoading, TasqMixin, GptMixin) {
  tasqOptionsOpen = false;

  showAddManualTasq = false;

  chartsLoading = false;

  addTasqAssetType = '';

  signalChartTime = 14;

  isOnlineApp = navigator.onLine;

  failedToLoadProductionData = false;

  autoCompleteTags: any = [];

  tag: any = '';

  tags: any = [];

  subPredictionList: any = [];

  multiTasqType = '';

  activeTasqTab = 'Feedback';

  loadingTasqs = false;

  showDataLoadingFeedback = false;

  showComment = false;

  customSignalRange: any = { startDate: null, endDate: null };

  showActionDialog: boolean = false;

  actionErrors: any = [];

  chartTimes: any[] = this.$getConst('CHART_TIMES');

  get stepper() {
    return tasqFeedbackModule.stepper;
  }

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }

  addTasqDidSelectAssetTasq() {}

  get isBatchResponding() {
    return tasqsListModule.isBatchResponding;
  }

  get selectedCustomRange() {
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }

  get dateRange() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      dateRange: { startDate, endDate },
    };
  }

  get selectedEditItemsCount() {
    return tasqsListModule.checkedTasqs.length;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  resetTasq() {
    tasqFeedbackModule.resetAllData();
  }

  editTasqs() {
    if (tasqsListModule.isEditing) {
      this.closeEditToolbar();
    } else {
      tasqsListModule.setActiveTasq('');
      tasqsListModule.setIsEditing(true);
    }
  }

  closeEditToolbar() {
    tasqsListModule.setIsEditing(false);
    this.activeTasqTab = 'Signals';
    tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    tasqsListModule.resetCheckedTasqs();
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setShowTasqDetailsMobile(false);
    assetsModule.setActiveProducingTasq('');

    this.$router.push({ name: 'Tasqs' });
  }

  get tasqListLevel(): any {
    if (this.activeTasq === null) {
      return 'WELL';
    }
    return this.activeTasq?.level;
  }

  get tasqModuleListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
    return this.$route.query.type;
  }

  get signalDescriptions(): any {
    return tasqSignalsModule.signalDescriptions;
  }

  get activeBatchFeedbackTasq() {
    if (tasqsListModule.checkedTasqs.length > 0) {
      return tasqsListModule.tasqById(tasqsListModule.checkedTasqs[0]);
    }
    return null;
  }

  get wellWaitingOn() {
    let padWaitingOn = null;
    const wellWaitingOn = gptModule.waitingOnStatusByWellName(this.activeTasq.wellName);
    if (this.activeTasq.padName) {
       padWaitingOn = gptModule.waitingOnStatusByWellName(this.activeTasq.padName);
    }

    return this.getRcentWaitingOnStatus({ wellWaitingOn, padWaitingOn });
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        assetsModule.setActiveProducingTasq('');
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
    }

    if (this.$route.query.type === 'id' && this.$route.params.id !== null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    // @ts-ignore
    if (
      tasqsListModule.activeTasq
      && this.$route.params.id !== null
      && this.$route.query.type === 'producing'
      && tasqsListModule.activeTasq
      && tasqsListModule.activeTasq.level === 'PAD'
    ) {
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  get showTasqDetailsMobile() {
    return tasqsListModule.showTasqDetailsMobile;
  }

  get reassignUsers() {
    return accountModule.reassignmentList
      .map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  async setActiveTasqDetailTab(tabName) {
    this.activeTasqTab = tabName;
    this.chartsLoading = true;
    await sleep(1000);
    this.chartsLoading = false;
  }

  async snoozeTasqForToday() {
    const tasqID = this.activeTasq?.id;
    this.startDataLoading();
    const action = {
      reason: 'Snoozed',
      comment: '',
      date: new Date().setDate(new Date().getDate() + 1),
      id: this.activeTasq?.id,
      startDate: new Date(),
    };
    const successText = 'Successfully snoozed!';
    this.snoozeTasqPopup = false;

          this.action.wellMetaData = {
            operator_name: this.operatorDetails.operatorName,
            operator_id: this.operatorDetails.operatorID,
            nodeid: this.activeTasq.wellName,
            level: this.activeTasq.level,
    };
    await tasqActionsModule.updateTasqStatus(action);
    this.$router.push({ name: 'Tasqs' });
    await sleep(2000);
    await tasqsListModule.getRawTasq({ PredictionID: tasqID });

    this.stopDataLoading();
    if (successText) {
      this.$eventBus.$emit(SHOW_ALERT, successText);
    }
  }

  clearSelectedTasq() {
    tasqsListModule.setActiveTasq('');
    tasqsListModule.setShowTasqDetailsMobile(false);
    assetsModule.setActiveProducingTasq('');
    if (this.isBatchResponding) {
      this.closeEditToolbar();
    }

    this.$router.push({ name: 'Tasqs' });
  }

  async updateTasqPrediction(data) {
    await tasqsListModule.getTasq({
      PredictionID: data.predictionId,
    });
    this.multiTasqType = data.name;
  }

  fetchActions() {
    const forms: any = JSON.parse(JSON.stringify(metaDataModule.formBuilderData));
    const formsMetaData: any = forms.reduce((r, a) => {
      const aData = a;
      const rData = r;
      if (!aData.category) {
        aData.category = 'None';
      }
      rData[aData.category] = rData[aData.category] || [];
      rData[aData.category].push(aData);
      return rData;
    }, Object.create(null));
    const actionBuilderActions = Object.keys(formsMetaData).map((key) => ({
      title: key,
      actions: formsMetaData[key],
    }));
    tasqFeedbackModule.setAvailableForms(actionBuilderActions);
  }

  async onlineMode() {
    this.isOnlineApp = true;
  }

  async offlineMode() {
    this.isOnlineApp = false;
  }

  async created() {
    tasqsListModule.setIsMobileView(true);
    tasqsListModule.setActivePage('Tasq');

    window.addEventListener('online', this.onlineMode);
    window.addEventListener('offline', this.offlineMode);

    this.isOnlineApp = await isOnline();

    await metaDataModule.getActionsBuilder({ useExisting: true });

    tasqsListModule.setFromNowBack(0);

    this.fetchActions();

    this.sleep(8000).then(() => {
      const promiseList: any = [];
      promiseList.push(accountModule.getReassignmentList({ useExisting: true }));
      Promise.all(promiseList).then(
        async () => {
          if (this.isOnlineApp) {
            datadogRum.init({
              applicationId: '42a5cbbe-cee7-4b16-b31c-27f8330f507b',
              clientToken: 'pubb848c6a76f8811fde5cd24dc0264f13f',
              site: 'datadoghq.com',
              service: 'tasq',
              env: getConfigEnv('BRANCH_NAME'),
              // Specify a version number to identify the deployed version of your application in Datadog
              version: workflowModule.user.email,
              sampleRate: 100,
              premiumSampleRate: 100,
              trackInteractions: true,
              defaultPrivacyLevel: 'allow',
            });

            datadogRum.startSessionReplayRecording();
          }
        },
        (err: any) => {
          // eslint-disable-next-line no-console
          console.log(err.message);
        },
      );
    });

    this.loadingTasqs = true;
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
    assetsModule.setActiveProducingTasq('');
    tasqsListModule.setActivePage('');
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('online', this.onlineMode);
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('offline', this.offlineMode);
  }

  openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }

  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      startDate: new Date(),
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    switch (action) {
      case 'reject':
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case 'reassign':
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case 'wait':
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case 'add':
        this.showAddManualTasq = true;
        break;
      default:
        break;
    }
  }

  prepareAddManualAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    switch (action) {
      case 'add':
        this.action.type = action;
        this.action.title = '';
        this.addTasqAssetType = 'Well';
        this.action.well = this.activeTasq?.wellName;
        this.action.confirmText = 'Add tasq';
        this.action.person = {
          value: workflowModule.user.email,
          text: `${workflowModule.user.firstName} ${workflowModule.user.lastName}`,
        };
        this.$dialog.show('actionModal');
        break;
      default:
        break;
    }
  }

  validateDialog(type) {
    if (type === 'reassign') {
      if (!this.action.person) {
        this.actionErrors.push('person');
      }
    } else if (type === 'wait') {
      if (!this.action.date) {
        this.actionErrors.push('date');
      }
      if (!this.action.startDate) {
        this.actionErrors.push('startDate');
      }
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }

      if (this.action.date && this.action.startDate && this.action.date < this.action.startDate) {
        this.actionErrors.push('invalidEndDate');
      }
    } else if (type === 'reject') {
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
    } else if (type === 'add') {
      if (!this.addTasqAssetType) {
        this.actionErrors.push('addTasqAssetType');
      } else {
        if (!this.action.jobType) {
          this.actionErrors.push('jobType');
        }
        if (!this.action.person) {
          this.actionErrors.push('person');
        }
        if (!this.action.well) {
          this.actionErrors.push('well');
        }
      }
    }
  }

  async onActionDialogClosed(response) {
    let successText = ''!;
    this.actionErrors = [];
    const tasqID = this.activeTasq?.id;

    this.startDataLoading();
    if (response.isOk) {
      this.validateDialog(this.action.type);
      if (this.actionErrors.length) {
        this.openDialog();
        return;
      }
      switch (this.action.type) {
        case 'reassign':
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case 'reject':
          successText = 'Successfully Rejected!';

          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case 'wait':
          successText = 'Successfully added to waitlist!';
          this.action.wellMetaData = {
            operator_name: this.operatorDetails.operatorName,
            operator_id: this.operatorDetails.operatorID,
            nodeid: this.activeTasq.wellName,
            level: this.activeTasq.level,
        };
          await tasqActionsModule.updateTasqStatus(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case 'add':
          successText = 'Tasq added successfully!';
          this.action.assetType = this.addTasqAssetType;
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }

      this.closeEditToolbar();
      this.action = {};
      this.showActionDialog = false;

      this.$router.push({ name: 'Tasqs' });

      await sleep(3000);
      await tasqsListModule.getRawTasq({
        PredictionID: tasqID,
      });

      this.stopDataLoading();
      if (successText) {
        this.$eventBus.$emit(SHOW_ALERT, successText);
      }
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get dateRangeCustomPreset() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      'Last month': [
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
      'Last 3 months': [
        new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
      'Last 6 months': [
        new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
      'Last Year': [
        new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
    };
  }

  async fetchTasq(predictionID = null, loadingFromSubPrediction = false) {
    this.isOnlineApp = navigator.onLine;
    if (!this.isOnlineApp) {
      if (
        this.$route.params.id != null
        && this.$route.params.id !== ''
        && this.$route.query.type === 'id'
      ) {
        tasqsListModule.setActiveTasq(this.$route.params.id);
      } else {
        // assetsModule.setActiveProducingTasq(this.$route.params.id);
      }

      this.showDataLoadingFeedback = false;
      this.chartsLoading = false;
      tasqsListModule.setTasqEventHistoryList([]);
      workflowModule.setWellHistory([]);

      return;
    }

    this.showDataLoadingFeedback = true;
    this.chartsLoading = true;

    tasqsListModule.setTasqEventHistoryList([]);
    workflowModule.setWellHistory([]);

    this.signalChartTime = 14;

    if (!loadingFromSubPrediction) {
      tasqsListModule.setFromNowBack(0);
      tasqSignalsModule.resetSignals();
      tasqProductionDataChartModule.resetProductionData();
      this.customSignalRange = { startDate: null, endDate: null };
    }

    this.isOnlineApp = navigator.onLine;
    if (predictionID != null) {
      // @ts-ignore
      if (!loadingFromSubPrediction) {
        // @ts-ignore
        tasqsListModule.setActiveTasq(predictionID);
        if (tasqsListModule.activeTasq === null) {
          if (this.tasqListLevel.toLowerCase() !== 'pad') {
            await tasqsListModule.getTasq({
              PredictionID: this.$route.params.id,
            });
          }
        }
      }
    } else if (
      this.$route.params.id != null
      && this.$route.params.id !== ''
      && this.$route.query.type === 'id'
    ) {
      if (!this.isOnlineApp) {
        tasqsListModule.setActiveTasq(this.$route.params.id);
      } else if (this.tasqListLevel.toLowerCase() !== 'pad') {
        await tasqsListModule.getTasq({
          PredictionID: this.$route.params.id,
        });
      }
    } else {
      assetsModule.setActiveProducingTasq(this.$route.params.id);
    }

    if (this.activeTasq !== null && this.activeTasq !== null) {
      this.showDataLoadingFeedback = false;
      this.chartsLoading = false;
    }
  }

  async tagsAdded(newTag) {
    this.tags.push(newTag.toLowerCase());
    await scheduleModule.addTagsToWell({
      node_id: this.activeTasq?.wellName,
      tags: this.tags,
    });
  }

  async tagsChanged(newTag) {
    await scheduleModule.addTagsToWell({
      node_id: this.activeTasq?.wellName,
      tags: [newTag.toLowerCase()].concat(this.tags),
    });
  }

  async tagsRemove(removeT) {
    const index = this.tags.indexOf(removeT);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    await scheduleModule.addTagsToWell({
      node_id: this.activeTasq?.wellName,
      tags: this.tags,
    });
  }

  @Watch('$route.params.id', {
    immediate: true,
  })
  async onTasqRouteIdChange(id: string) {
    if (id) {
      const { tab } = this.$route.query;
      if (tab === 'Feedback') {
        this.activeTasqTab = tab;
      } else {
        this.activeTasqTab = 'Feedback';
      }
      await this.fetchTasq().then(() => {
        tasqsListModule.updateTasqViewsCount({ predictionID: id });
      });
    } else {
      tasqsListModule.setActiveTasq('');
    }
    this.multiTasqType = '';
  }
}
